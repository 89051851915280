<template lang="pug">
.user-menu
  collapse-transition(v-if="$store.state.auth.loggedIn")
    user-menu-auth(v-if="showMenu")
  user-menu-non-auth-mobile(v-else)
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'

export default {
  name: 'UserMenuMobileComponent',

  components: {
    CollapseTransition,
    UserMenuAuth: () => import('./UserMenuAuth'),
    UserMenuNonAuthMobile: () => import('./UserMenuNonAuthMobile')
  },

  props: {
    showMenu: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    // collapse menu on login/logout
    '$store.state.auth.loggedIn'() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="sass" scoped>
.user-menu
  z-index: 2002
  &-auth
    position: absolute
    background-color: white
    top: 74px
    right: 0
    left: 0
    margin: auto
    border: 1px solid $border-color
    padding: 15px
    z-index: 50
    width: 300px
</style>
